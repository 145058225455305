<template>
  <!-- 获取id 需要修改 -->
  <div
    class="tc-report"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 页面功能 -->
    <div class="tool">
      <ul>
        <li>
          <img src="../../assets/images/refresh.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="../../assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 头部工具 -->
    <div
      class="tc-report-header"
      v-if="$route.path.split('/')[1] != 'answer_content'"
    >
      <!-- <router-link target="_blank" :to="{ path: '/downpdf', query: uid }">
        <el-button @click="connotPrint" type="primary" plain>打印报告</el-button>
      </router-link> -->
      <el-button @click="connotPrint" type="primary" plain>打印报告</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="showSetReportBox"
        >打印设置</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="infoEditState" v-if="!editState"
        >编辑内容</el-button
      >
      <el-button type="primary" plain @click="seveEditInfo" v-if="editState"
        >保存</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="downWord">导出Word</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="upInspect">审核</el-button>
    </div>
    <!-- 报告设置模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="打印设置"
      :visible.sync="SetReportBox"
      width="30%"
      center
      @close="resc"
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-form-item label="基本信息：">
          <el-radio-group v-model="form.scope_basic">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="图形：">
          <el-radio-group v-model="form.scope_graph">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="因子表：">
          <el-radio-group v-model="form.scope_factor">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分数级别：">
          <el-radio-group v-model="form.scope_score">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="因子说明：">
          <el-radio-group v-model="form.scope_explain">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="指导建议：">
          <el-radio-group v-model="form.scope_suggest">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="测试师：">
          <el-radio-group v-model="form.scope_assessor">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用户答案：">
          <el-radio-group v-model="form.scope_question">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SetReportBox = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 内容主题 -->
    <div v-show="true" id="ccc">
      <div
        class="tc-report-main"
        v-for="(item, key) in tableInfoList"
        :key="key"
      >
        <!-- 普通报告 -->
        <div
          class="content"
          id="content_a"
          :style="{ fontSize }"
          v-if="!item.results"
        >
          <!-- 标题 -->
          <div class="report-title">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          
          <div class="userInfo" v-show="form.scope_basic == 1">
            <table>
              <tr>
                <td>
                  姓名：
                  <span>{{ item.customer.name }}</span>
                </td>
                <td>
                  性别：
                  <span>{{ item.customer.sex }}</span>
                </td>
                <td>
                  年龄：
                  <span>{{ item.customer.ages }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  编号：
                  <span>{{ item.customer.account }}</span>
                </td>
                <td>
                  科室：
                  <span>{{ item.customer.department }}</span>
                </td>
                <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <!-- 测试结果title -->
          <p class="test-msg" v-show="form.scope_graph == 1">测试结果:</p>
          <!-- 测试-图 -->
          <div class="chart" v-show="form.scope_graph == 1">
            <!-- 90,圆形图 -->
            <div
              :id="'chart-report' + key"
              :style="{ width: '100%', height: '100%' }"
            ></div>
          </div>
          <!-- 测试-表 -->
          <div class="table" v-show="form.scope_factor == 1">
            <div class="table-main">
              <el-row v-if="item.factor_results.length <= 3">
                <!-- 因子数小于三 -->
                <el-col>
                  <table class="one">
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                    <!-- -------------检测项目---------------- -->
                    <tr
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <td>{{ item2.name }}</td>
                      <td>{{ item2.mark }}</td>
                      <td>{{ item2.score }}</td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
              <el-row v-if="item.factor_results.length > 3">
                <table class="results-two" cellspacing="0">
                  <thead>
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <template v-if="item.factor_results[key2 * 2]">
                        <td>{{ item.factor_results[key2 * 2].name }}</td>
                        <td>{{ item.factor_results[key2 * 2].mark }}</td>
                        <td>{{ item.factor_results[key2 * 2].score }}</td>
                      </template>
                      <template v-if="item.factor_results[key2 * 2 + 1]">
                        <td>{{ item.factor_results[key2 * 2 + 1].name }}</td>
                        <td>{{ item.factor_results[key2 * 2 + 1].mark }}</td>
                        <td>{{ item.factor_results[key2 * 2 + 1].score }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </el-row>
            </div>
          </div>
          <!-- 测试结果title -->
          <p
            class="test-msg"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            报告分析:
          </p>
          <!-- 每个项目分析 -->
          <div
            class="analyze"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            <!-- v-show  隐藏没内容的 因子 -->
            <table
              v-for="(item2, key2) in item.factor_results"
              :key="key2"
              v-show="item2.comment != '' || item2.advice != ''"
            >
              <!--  v-show="item2.comment" -->
              <tr class="analyze-title" v-show="form.scope_score == 1">
                <span v-if="!editState">【{{ item2.name }}】</span>
                <span v-if="editState">
                  检测项目
                  <el-input
                    v-model="item2.name"
                    placeholder="请输入内容"
                  ></el-input>
                </span>
                分数级别：
                <span v-if="!editState">【{{ item2.mark }} 】</span>
                <el-input
                  v-model="item2.mark"
                  placeholder="请输入内容"
                  v-if="editState"
                ></el-input
                >得分：
                <span v-if="!editState">{{ item2.score }}</span>
                <span v-if="referenceShowObj[item.measure_title]&&item2.reference">
                  {{handleTagReference(item2.reference,item2.score)}}
                </span>
                <el-input
                  v-model="item2.score"
                  placeholder="请输入内容"
                  v-if="editState"
                ></el-input>
                <!-- v-if="item2.comment" -->
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td class="analyze-title">【说 明】</td>
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td v-html="item2.comment" v-if="!editState"></td>
                <td>
                  <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="请输入内容"
                    v-model="item2.comment"
                    v-if="editState"
                  ></el-input>
                </td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td class="analyze-title">【指导建议】</td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td v-html="item2.advice" v-if="!editState"></td>
                <td>
                  <el-input
                    type="textarea"
                    :rows="8"
                    placeholder="请输入内容"
                    v-model="item2.advice"
                    v-if="editState"
                  ></el-input>
                </td>
              </tr>
            </table>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <el-select v-model="uid.degree" placeholder="请选择配合程度">
              <el-option label="低" value="低"></el-option>
              <el-option label="中" value="中"></el-option>
              <el-option label="高" value="高"></el-option>
            </el-select>
          </div>
          <!-- 用户答题 -->
          <div
            class="user-answers"
            v-if="item.question && form.scope_question == 1"
          >
            <p class="test-msg">用户答案:</p>
            <div
              class="user-answers-item"
              v-for="(item2, key) in item.question"
              :key="key"
            >
              <div class="title" v-html="item2.question"></div>
              <span class="answers" v-html="'【答案】:' + item2.answer"></span>
            </div>
          </div>
          <!-- 医生签名 -->
          <div class="signature">
            <span class="title">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评师:_______________</span
              >
              报告医生 ：
              <span class="signature_img">
                <img :src="item.admin.sign_img" alt width="150" />
              </span>
            </span>
            <span class="title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
        </div>
        <!-- 明尼苏达多相个性测查表 -->
        <div
          v-if="item.results"
          class="content minnesota"
          :style="{ fontSize }"
        >
          <!-- 标题  -->
          <div class="report-title">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div class="userInfo" v-show="form.scope_basic == 1">
            <table>
              <tr>
                <td>
                  姓名：
                  <span>{{ item.customer.name }}</span>
                </td>
                <td>
                  性别：
                  <span>{{ item.customer.sex }}</span>
                </td>
                <td>
                  年龄：
                  <span>{{ item.customer.ages }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  编号：
                  <span>{{ item.customer.account }}</span>
                </td>
                <td>
                  科室：
                  <span>{{ item.customer.department }}</span>
                </td>
                <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
              <tr>
                <td>
                  项目数:
                  <span>{{
                    item.results.mmpi.statistics.dashi +
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
                <td>
                  答是数量:
                  <span>{{ item.results.mmpi.statistics.dashi }}</span>
                </td>
                <td>
                  答否数量:
                  <span>{{ item.results.mmpi.statistics.dafou }}</span>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <!-- MMPI 基 本 量 表 分 -->
          <div class="mmpi">
            <div class="title">MMPI 基 本 量 表 分</div>
            <el-divider></el-divider>
            <div class="mmpi_table">
              <!-- 2 -->
              <table>
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span>{{ key }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>分数：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <el-divider></el-divider>
              <!-- 4 -->
              <table>
                <tr>
                  <td>
                    <span>原始分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.oldCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>K 校 正 分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>中国常模不做K校正的T分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kNoCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>中国常模做K校正的T分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <el-divider></el-divider>
              <div class="mmpi_summarize">
                <span>两点峰型:{{ item.results.mmpi.statistics.twofx }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>矛盾题:{{ item.results.mmpi.statistics.maodun }}</span>
                <el-divider direction="vertical"></el-divider>
                <span
                  >答"是"的比例:{{
                    item.results.mmpi.statistics.pro_yes.toFixed(2)
                  }}
                  %</span
                >
                <el-divider direction="vertical"></el-divider>
                <span
                  >答"否"的比例:{{
                    item.results.mmpi.statistics.pro_no.toFixed(2)
                  }}
                  %</span
                >
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <br />
          <!-- T 分 顺 序 表 -->
          <div class="t">
            <div class="title">T 分 顺 序 表</div>
            <el-divider></el-divider>
            <div class="t_table">
              <!-- 分 -->
              <table>
                <tr>
                  <td
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span>{{ key }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <!-- 表格 -->
              <table>
                <tr>
                  <td>因子量表</td>
                  <td>T 分</td>
                  <td>低分者特征</td>
                  <td>
                    <span>10</span>
                    <span>20</span>
                    <span>30</span>
                    <span>40</span>
                    <span>50</span>
                    <span>60</span>
                    <span>70</span>
                    <span>80</span>
                    <span>90</span>
                    <span>100</span>
                    <span>110</span>
                  </td>
                  <td>高分者特征</td>
                </tr>
                <tr v-for="(res, key2) in item.results.tcore.tdes" :key="key2">
                  <!-- 因 返回的数据 0-3为 一条td，故 %3 处理 不显示  -->
                  <template v-if="key2 % 3 == 0">
                    <!-- 因子量表 -->
                    <td>
                      {{ res.tagdes }}
                      <br />
                      <span
                        v-html="item.results.tcore.tdes[key2 + 1].tagdes"
                      ></span>
                    </td>
                    <!-- 分数 -->
                    <td>{{ res.score }}</td>
                    <!-- 低分者特征 -->
                    <td>
                      {{ res.ddesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].ddesc }}
                    </td>
                    <!-- 游标 -->
                    <td>
                      <span
                        class="cursor"
                        :style="`left:${
                          (res.score / 10) * 25.91 - 25.91 / 2 - 4
                        }px`"
                      >
                        <i class="el-icon-caret-top"></i>
                      </span>
                    </td>
                    <!-- 高 分者特征 -->
                    <td>
                      {{ res.gdesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].gdesc }}
                    </td>
                  </template>
                </tr>
              </table>
              <br />
              <!-- 图表 -->
              <div class="chart" v-show="form.scope_graph == 1">
                <!-- 90,圆形图 -->
                <div
                  :id="'chart-report' + key"
                  :style="{ width: '100%', height: '100%' }"
                ></div>
              </div>
              <!-- 预测内容 -->
              <div class="forecast" v-if="item.results.war">
                <table>
                  <caption>
                    危险行为预测
                  </caption>
                  <tr>
                    <td style="text-align: center">预测内容</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                  </tr>
                  <tr v-for="(res, k, index) in item.results.war" :key="index">
                    <td style="text-align: left">{{ k }}:</td>
                    <td
                      v-for="index2 in 10"
                      :key="index2"
                      :colspan="res"
                      style="background: rgb(63, 62, 62)"
                      v-show="index2 == 1"
                    ></td>
                  </tr>
                  <!-- 程度 -->
                  <tr style="transform: translateX(4px); font-weight: 700">
                    <td></td>
                    <td>小</td>
                    <td></td>
                    <td></td>
                    <td>中</td>
                    <td></td>
                    <td>大</td>
                    <td></td>
                    <td>极大</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <!-- 临 床 亚 量 表 -->
          <div class="conclusion">
            <table>
              <caption>
                临 床 亚 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k, index) in item.results.lc" :key="index">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 附加量表 -->
          <div class="conclusion" v-if="item.results.fjlb">
            <table>
              <caption>
                附 加 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.fjlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 适应不良量表 -->
          <div class="conclusion" v-if="item.results.sybllb">
            <table>
              <caption>
                适 应 不 良 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.sybllb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 预测性量表 -->
          <div class="conclusion" v-if="item.results.ycxlb">
            <table>
              <caption>
                预 测 性 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.ycxlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 性功能和性体验障碍量表 -->
          <div class="conclusion" v-if="item.results.xgn">
            <table>
              <caption>
                性 功 能 和 性 体 验 障 碍 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.xgn" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 内容量表 -->
          <div class="conclusion" v-if="item.results.nrlb">
            <table>
              <caption>
                内 容 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.nrlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <br />
          <!--辅助诊断意见 -->
          <div class="auxiliary" v-if="item.results.xlzz">
            <div class="title">* * *辅助诊断意见(仅供临床参考)* * *</div>
            <el-divider></el-divider>
            <div class="auxiliary_title">* * *心理症状提示* * *</div>
            <el-divider></el-divider>
            <div class="auxiliary_main">
              <div
                class="auxiliary_item"
                v-for="(r2, k2) in item.results.xlzz"
                :key="k2"
              >
                <div class="item_title">
                  {{ k2 + 1 }}、{{ r2.title }}({{ r2.fz }}/{{ r2.fm }})：
                </div>
                <div class="item_content" v-html="r2.desc"></div>
              </div>
            </div>
          </div>
          <br />
          <!-- 个性心理描述 -->
          <div class="describe">
            <div class="title">* * * 个性心理描述 * * *</div>
            <el-divider></el-divider>
            <!-- 测谎 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>测谎</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.l"></p>
              </div>
            </div>
            <!-- 总效度分析 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>总效度分析</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx1"></p>
              </div>
            </div>
            <!-- 个性逐项分析 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>个性逐项分析</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx2"></p>
              </div>
            </div>
            <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>个性综合分析(本人常意识不到,应激,虚弱和疾病时明显)</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx3"></p>
              </div>
            </div>
          </div>
          <div class="remind">
            <p style="text-align: center">
              ******（本报告仅供临床参考，不作诊断证明之用）******
            </p>
          </div>
          <!-- 总 体 描 述 -->
          <div class="zDescribe" v-if="item.results.ztyx">
            <div class="zDescribe_title">总 体 描 述</div>
            <el-divider></el-divider>
            <div class="zDescribe_content">
              <p>{{ item.results.ztyx.desc }}</p>
            </div>
            <div class="zDescribe_msg">
              <p>
                检 查 结 果:
                <span>{{ item.results.ztyx.con }}</span>
              </p>
            </div>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <el-select v-model="uid.degree" placeholder="请选择配合程度">
              <el-option label="低" value="低"></el-option>
              <el-option label="中" value="中"></el-option>
              <el-option label="高" value="高"></el-option>
            </el-select>
          </div>
          <!-- 医生建议 -->
          <br />
          <div class="suggest">
            <p class="suggest_msg">医生建议：</p>
            <div class="suggest_content"></div>
            <!-- 医生签名 -->
            <div class="signature">
              <span class="title">
                <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评师:_______________</span
                >
                报告医生 ：
                <span class="signature_img">
                  <!-- <img :src="item.admin.sign_img" alt width="150" /> -->
                </span>
              </span>
              <span class="title data">报告日期 ：</span>
              <span>{{ item.customer.evaluating_time }}</span>
            </div>
          </div>
          <div class="remind">
            <p style="text-align: center">
              ******此报告仅供参考，请以医师诊断为准。******
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-----------------导出word-------------------->
    <div id="downWord" v-show="false">
      <!-- 内容主题 -->
      <div
        v-for="(item, key) in tableInfoList"
        :key="key"
        :id="'downWord' + key"
      >
        <!-- 医院名称 -->
        <div class="units_name" style="margin-bottom: 30px">
          <p style="font-size: 33px; font-weight: 700; text-align: center">
            {{ config.unit_name }}
          </p>
        </div>
        <!-- 普通报告 -->
        <div
          class="content"
          id="content_a"
          style="
            width: 900px;
            margin: auto;
            background: white;
            padding: 20px 60px;
            box-sizing: border-box;
          "
          v-if="!item.results"
        >
          <!-- 标题 -->
          <div class="report-title" style="font-size: 30px; text-align: center">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息导出 -->
          <div
            class="userInfo"
            v-if="Number(form.scope_basic) == 1"
            style="margin-top: 20px"
          >
            <table
              style="
                margin: auto;
                width: 100%;
                border-top: 1px black dashed;
                border-left: 1px black dashed;
              "
            >
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  姓名：
                  <span style="font-weight: 300">{{ item.customer.name }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  性别：
                  <span style="font-weight: 300">{{ item.customer.sex }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  年龄：
                  <span style="font-weight: 300">{{
                    item.customer.ages
                  }}</span>
                </td>
              </tr>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  编号：
                  <span style="font-weight: 300">{{
                    item.customer.account
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  科室：
                  <span style="font-weight: 300">{{
                    item.customer.department
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  报告日期：
                  <span style="font-weight: 300">{{
                    item.customer.evaluating_time
                  }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr
                  style="width: 100%; font-weight: 700"
                  v-if="item.customer.archives.slice(0, 3).length > 0"
                >
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr
                  style="width: 100%; font-weight: 700"
                  v-if="item.customer.archives.slice(3, 6).length > 0"
                >
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr
                  style="width: 100%; font-weight: 700"
                  v-if="item.customer.archives.slice(6, 9).length > 0"
                >
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr
                  style="width: 100%; font-weight: 700"
                  v-if="item.customer.archives.slice(9, 12).length > 0"
                >
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <!-- 测试结果title -->
          <p
            style="
              padding-left: 0px;
              margin-top: 10px;
              font-size: 25px;
              color: orange;
              text-shadow: black 2px 1px 1px;
              font-weight: 700;
            "
            v-show="form.scope_graph == 1"
          >
            测试结果:
          </p>
          <!-- 测试-图 -->
          <div
            style="margin-top: 10px; height: 250px"
            v-show="form.scope_graph == 1"
          >
            <!-- 90,圆形图 -->
            <!-- <div :id="'chart-report'+key" :style="{width: '100%', height: '100%',margin: '0 auto'}"></div> -->
            <div style="margin: '0 auto '; text-align: center; width: '100%'">
              <img :src="wordImgUrl[key]" alt />
            </div>
          </div>
          <!-- 测试-表 -->
          <div
            class="table"
            style="
              overflow: hidden;
              margin-top: 15px;
              box-sizing: border-box;
              padding: 20px 0;
              border-top: 1px black solid;
              border-bottom: 1px black solid;
            "
            v-if="Number(form.scope_factor) == 1"
          >
            <div class="table-main">
              <el-row v-if="item.factor_results.length <= 3">
                <!-- 因子数小于三 -->
                <el-col>
                  <table
                    class="one"
                    style="
                      width: 100%;
                      border-top: 1px black dashed;
                      border-left: 1px black dashed;
                    "
                  >
                    <tr style="width: 100%">
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        得分
                      </th>
                    </tr>
                    <!-- -------------检测项目---------------- -->
                    <tr
                      style="width: 100%"
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.name }}
                      </td>
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.mark }}
                      </td>
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.score }}
                      </td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
              <el-row v-if="item.factor_results.length > 3">
                <table
                  class="results-two"
                  cellspacing="0"
                  style="
                    width: 100%;
                    border-top: 1px dashed black;
                    border-left: 1px dashed black;
                  "
                >
                  <thead>
                    <tr>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 160px;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 180px;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 50px;
                        "
                      >
                        得分
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 160px;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 180px;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 50px;
                        "
                      >
                        得分
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <template v-if="item.factor_results[key2 * 2]">
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 160px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].name }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 180px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].mark }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 50px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].score }}
                        </td>
                      </template>
                      <template v-if="item.factor_results[key2 * 2 + 1]">
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 160px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].name }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 180px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].mark }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 50px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].score }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </el-row>
            </div>
          </div>
          <!-- 测试结果title -->
          <p
            style="
              padding-left: 0px;
              margin-top: 10px;
              font-size: 25px;
              color: orange;
              text-shadow: black 2px 1px 1px;
              font-weight: 700;
            "
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            报告分析:
          </p>
          <!-- 健康状态 -->
          <!-- 每个项目分析 -->
          <div
            class="analyze"
            style="margin-top: 20px; padding-bottom: 10px"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            <!-- v-show  隐藏没内容的 因子 -->
            <table
              style="
                padding: 10px 0;
                border-bottom: 1px black solid;
                width: 100%;
              "
              v-for="(item2, key2) in item.factor_results"
              :key="key2"
              v-show="item2.comment != '' || item2.advice != ''"
            >
              <!--  v-show="item2.comment" -->
              <tr
                style="
                  text-indent: 0;
                  padding-top: 10px;

                  font-weight: 700;
                "
                class="analyze-title"
                v-show="form.scope_score == 1"
              >
                【{{
                  item2.name
                }}】 分数级别：{{
                  item2.mark
                }}
                <span>得分：{{ item2.score }}</span>
                <span v-if="referenceShowObj[item.measure_title]&&item2.reference">（{{item2.reference}}）</span>
                <!-- v-if="item2.comment" -->
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td
                  style="
                    text-indent: 0;
                    padding-top: 10px;

                    font-weight: 700;
                  "
                  class="analyze-title"
                >
                  【说 明】
                </td>
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td
                  style="
                    text-indent: 2em;
                    letter-spacing: 3px;
                    line-height: 25px;
                  "
                  v-html="item2.comment"
                ></td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td
                  style="
                    text-indent: 0;
                    padding-top: 10px;
                    // font-size: 16px;
                    font-weight: 700;
                  "
                  class="analyze-title"
                >
                  【指导建议】
                </td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td
                  style="
                    text-indent: 2em;
                    letter-spacing: 3px;
                    line-height: 25px;
                  "
                  v-html="item2.advice"
                ></td>
              </tr>
            </table>
          </div>
          <!-- 配合程度 -->
          <div
            class="degree"
            style="margin: 10px 0"
            v-if="isDegree && uid.degree"
          >
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ uid.degree }}</p>
          </div>
          <!-- 用户答题 -->
          <div class="user-answers" v-if=" Number(form.scope_question) == 1">
            <p
              class="test-msg"
              style="
                padding-left: 0px;
                margin-top: 10px;
                font-size: 25px;
                color: orange;
                text-shadow: black 2px 1px 1px;
                font-weight: 700;
              "
            >
              用户答案:
            </p>
            <div
              class="user-answers-item"
              v-for="(item2, key) in item.question"
              :key="key"
              style="margin-bottom: 10px; color: black"
            >
              <div class="title" v-html="item2.question"></div>
              <span
                class="answers"
                style="font-weight: 700; display: inline-block"
                v-html="'【答案】:' + item2.answer"
              ></span>
            </div>
          </div>
          <!-- 医生签名 -->
          <div
            class="signature"
            style="position: relative; text-align: right; margin-top: 40px"
          >
            <span class="title" style="font-weight: 700">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评师:_______________</span
              >
              报告医生 ：
              <span
                class="signature_img"
                style="display: inline-block; min-width: 100px"
              >
                <img :src="signatureImg[key]" alt width="80" height="50" />
              </span>
            </span>
            <span class="title data" style="font-weight: 700; margin-left: 20px"
              >报告日期 ：</span
            >
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
        </div>
        <!-- 明尼苏达多相个性测查表 -->
        <div
          v-if="item.results"
          class="content minnesota"
          style="
            box-sizing: border-box;
            width: 900px;
            margin: auto;
            background: white;
          "
        >
          <!-- 标题  -->
          <div class="report-title" style="font-size: 30px; text-align: center">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div
            class="userInfo"
            v-show="form.scope_basic == 1"
            style="margin-top: 20px"
          >
            <table
              style="
                margin: 0 auto;
                width: 100%;
                border-top: 1px black dashed;
                border-left: 1px black dashed;
              "
            >
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  姓名：
                  <span style="font-weight: 300">{{ item.customer.name }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  性别：
                  <span style="font-weight: 300">{{ item.customer.sex }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  年龄：
                  <span style="font-weight: 300">{{
                    item.customer.ages
                  }}</span>
                </td>
              </tr>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  编号：
                  <span style="font-weight: 300">{{
                    item.customer.account
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  科室：
                  <span style="font-weight: 300">{{
                    item.customer.department
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  报告日期：
                  <span style="font-weight: 300">{{
                    item.customer.evaluating_time
                  }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  项目数:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dashi +
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  答是数量:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dashi
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  答否数量:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <!-- MMPI 基 本 量 表 分 -->
          <div class="mmpi" style="margin-top: 40px">
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              MMPI 基 本 量 表 分
            </div>
            <hr style="margin: 5px 0" />
            <div class="mmpi_table" style="margin-top: 20px">
              <!-- 共2行 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        height: 120px;
                        writing-mode: vertical-lr;
                        letter-spacing: 8px;
                      "
                    ></span>
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        height: 120px;
                        writing-mode: vertical-lr;
                        letter-spacing: 8px;
                      "
                      >{{ key }}</span
                    >
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >分数：</span
                    >
                  </td>
                  <td
                    style="
                            width:5.71%
                            text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ res }}</span
                    >
                  </td>
                </tr>
              </table>
              <hr style="margin: 5px 0" />
              <!-- 共4行 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >原始分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.oldCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >K 校 正 分：</span
                    >
                  </td>
                  <td
                    style="
                            width:5.71%
                            text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >中国常模不做K校正的T分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kNoCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >中国常模做K校正的T分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <hr style="margin: 5px 0" />
              <div class="mmpi_summarize" style="padding: 5px 0">
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >两点峰型:{{ item.results.mmpi.statistics.twofx }}</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >矛盾题:{{ item.results.mmpi.statistics.maodun }}</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >答"是"的比例:{{
                    item.results.mmpi.statistics.pro_yes
                  }}
                  %</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >答"否"的比例:{{
                    item.results.mmpi.statistics.pro_no
                  }}
                  %</span
                >
              </div>
              <hr style="margin: 5px 0" />
            </div>
          </div>
          <br />
          <!-- T 分 顺 序 表 -->
          <div class="t" style="margin-top: 40px">
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              T 分 顺 序 表
            </div>
            <hr style="margin: 5px 0" />
            <div class="t_table" style="margin-top: 20px">
              <!-- 分 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="flex: 1; display: flex; justify-content: center"
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ key }}</span
                    >
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="flex: 1; display: flex; justify-content: center"
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ res }}</span
                    >
                  </td>
                </tr>
              </table>
              <!-- 表格 -->
              <table
                style="
                  width: 100%;
                  font-size: 14px;
                  border: black 1px solid;
                  border-collapse: collapse;
                "
              >
                <tr>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    因子量表
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    T 分
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    低分者特征
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    高分者特征
                  </td>
                </tr>
                <tr v-for="(res, key2) in item.results.tcore.tdes" :key="key2">
                  <!-- 因 返回的数据 0-3为 一条td，故 %3 处理 不显示  -->
                  <template v-if="key2 % 3 == 0">
                    <!-- 因子量表 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.tagdes }}
                      <br />
                      <span
                        v-html="item.results.tcore.tdes[key2 + 1].tagdes"
                      ></span>
                    </td>
                    <!-- 分数 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.score }}
                    </td>
                    <!-- 低分者特征 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.ddesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].ddesc }}
                    </td>
                    <!-- 高 分者特征 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.gdesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].gdesc }}
                    </td>
                  </template>
                </tr>
              </table>
              <br />
              <!-- 图表 -->
              <div class="chart" v-show="form.scope_graph == 1">
                <!-- 90,圆形图 -->
                <!-- <div :id="'chart-report'+key" :style="{width: '100%', height: '100%'}"></div> -->
                <div
                  style="margin: '0 auto '; text-align: center; width: '100%'"
                >
                  <img :src="wordImgUrl[key]" alt />
                </div>
              </div>
              <!-- 预测内容 -->
              <div
                class="forecast"
                v-if="item.results.war"
                style="margin-top: 30px"
              >
                <table
                  style="
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 5px 5px;
                  "
                >
                  <caption
                    style="font-size: 16px; font-weight: 700; padding: 10px 0"
                  >
                    危险行为预测
                  </caption>
                  <tr>
                    <td style="text-align: center; font-weight: 700">
                      预测内容
                    </td>
                    <td style="width: 50px; text-align: right">1</td>
                    <td style="width: 50px; text-align: right">2</td>
                    <td style="width: 50px; text-align: right">3</td>
                    <td style="width: 50px; text-align: right">4</td>
                    <td style="width: 50px; text-align: right">5</td>
                    <td style="width: 50px; text-align: right">6</td>
                    <td style="width: 50px; text-align: right">7</td>
                    <td style="width: 50px; text-align: right">8</td>
                    <td style="width: 50px; text-align: right">9</td>
                    <td style="width: 50px; text-align: right">10</td>
                  </tr>
                  <tr
                    v-for="(res, k, index) in item.results.war"
                    :key="index"
                    style
                  >
                    <td style="text-align: left; width: 120px">{{ k }}:</td>
                    <td
                      v-for="index2 in res"
                      :key="index2"
                      style="
                        background: rgb(63, 62, 62);
                        width: 50px;
                        text-align: right;
                      "
                    ></td>
                  </tr>
                  <!-- 程度 -->
                  <tr style="transform: translateX(4px); font-weight: 700">
                    <td></td>
                    <td>小</td>
                    <td></td>
                    <td></td>
                    <td>中</td>
                    <td></td>
                    <td>大</td>
                    <td></td>
                    <td>极大</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <!-- 临 床 亚 量 表 -->
          <div class="conclusion" style="margin-top: 40px">
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                临 床 亚 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k, index) in item.results.lc" :key="index">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 附加量表 -->
          <div
            class="conclusion"
            v-if="item.results.fjlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                附 加 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.fjlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 适应不良量表 -->
          <div
            class="conclusion"
            v-if="item.results.sybllb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                适 应 不 良 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.sybllb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 预测性量表 -->
          <div
            class="conclusion"
            v-if="item.results.ycxlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                预 测 性 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.ycxlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 性功能和性体验障碍量表 -->
          <div
            class="conclusion"
            v-if="item.results.xgn"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                性 功 能 和 性 体 验 障 碍 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.xgn" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 内容量表 -->
          <div
            class="conclusion"
            v-if="item.results.nrlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                内 容 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.nrlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <br />
          <!--辅助诊断意见 -->
          <div
            class="auxiliary"
            v-if="item.results.xlzz"
            style="margin-top: 40px"
          >
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              * * *辅助诊断意见(仅供临床参考)* * *
            </div>
            <hr style="margin: 5px 0" />
            <div
              class="auxiliary_title"
              style="
                margin-top: 20px;
                font-weight: 700;
                font-size: 16px;
                text-align: center;
              "
            >
              * * *心理症状提示* * *
            </div>
            <hr style="margin: 5px 0" />
            <div class="auxiliary_main">
              <div
                class="auxiliary_item"
                v-for="(r2, k2) in item.results.xlzz"
                :key="k2"
                style="padding: 10px 0"
              >
                <div
                  class="item_title"
                  style="font-weight: 700; margin-bottom: 8px"
                >
                  {{ k2 + 1 }}、{{ r2.title }}({{ r2.fz }}/{{ r2.fm }})：
                </div>
                <div class="item_content" v-html="r2.desc"></div>
              </div>
            </div>
          </div>
          <br />
          <!-- 个性心理描述 -->
          <div class="describe" style="margin-top: 40px">
            <div class="title">* * * 个性心理描述 * * *</div>
            <hr style="margin: 5px 0" />
            <!-- 测谎 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  测谎
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.l"></p>
              </div>
            </div>
            <!-- 总效度分析 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  总效度分析
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx1"></p>
              </div>
            </div>
            <!-- 个性逐项分析 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  个性逐项分析
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx2"></p>
              </div>
            </div>
            <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  个性综合分析(本人常意识不到,应激,虚弱和疾病时明显)
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx3"></p>
              </div>
            </div>
          </div>
          <div
            class="remind"
            style="
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
            "
          >
            <p style="text-align: center">
              ******（本报告仅供临床参考，不作诊断证明之用）******
            </p>
          </div>
          <!-- 总 体 描 述 -->
          <div
            class="zDescribe"
            v-if="item.results.ztyx"
            style="margin-top: 60px"
          >
            <div
              class="zDescribe_title"
              style="font-weight: 700; text-align: center"
            >
              总 体 描 述
            </div>
            <hr style="margin: 5px 0" />
            <div
              class="zDescribe_content"
              style="margin-top: 40px; font-size: 14px"
            >
              <p>{{ item.results.ztyx.desc }}</p>
            </div>
            <div class="zDescribe_msg" style="margin-top: 50px">
              <p style="font-weight: 700">
                检 查 结 果:
                <span style="font-weight: 100">{{
                  item.results.ztyx.con
                }}</span>
              </p>
            </div>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ uid.degree }}</p>
          </div>
          <!-- 医生建议 -->
          <br />
          <div
            class="suggest"
            style="
              border-top: 1px black solid;
              margin-bottom: 5px;
              padding: 5px 0;
            "
          >
            <p class="suggest_msg" style="font-weight: 700">医生建议：</p>
            <div class="suggest_content" style="height: 200px"></div>
            <!-- 医生签名 -->
            <div
              class="signature"
              style="position: relative; text-align: right; margin-top: 40px"
            >
              <span class="title" style="font-weight: 700">
                <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评师:_______________</span
                >
                报告医生 ：
                <span
                  class="signature_img"
                  style="display: inline-block; min-width: 100px"
                >
                  <img :src="signatureImg[key]" alt width="80" height="50" />
                </span>
              </span>
              <span
                class="title data"
                style="font-weight: 700; margin-left: 20px"
                >报告日期 ：</span
              >
              <span>{{ item.customer.evaluating_time }}</span>
            </div>
          </div>
          <div
            class="remind"
            style="
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
            "
          >
            <p style="text-align: center">
              ******此报告仅供参考，请以医师诊断为准。******
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pageNext"></div>
      </div>
    </div>
    <!-- 等待导出模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="系统提示"
      :visible.sync="downLoad"
      :showClose="false"
      :closeOnClickModal="false"
      :closeOnCressEscape="false"
      width="600px"
    >
      <h3 v-if="downLoadNum < tableInfoList.length">
        正在生成第 {{ downLoadNum }} 份 - {{ downLoadTitle }}
      </h3>
      <template v-else>
        <h3>报告已全部生成，浏览器即将自动下载。请等待...</h3>
        <i class="downWordMsg" @click="twoDownWord()"
          >如果未自动下载,请点击我</i
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="downLoad = false"
            >关 闭</el-button
          ></span
        >
      </template>
    </el-dialog>
  </div>
</template> 
<script>
import $ from "jquery";
import { loadReport, save_meaconclusion } from "@/api/report.js";
import { max } from "moment";
import { updateConfig } from "@/api/comm.js";
import saveAs from "file-saver";
import jszip from "jszip";
import moment from "moment";
import "../../../public/static/jquery.wordexport";
import { log } from "util";
import { inspectUp } from "@/api/information.js";
export default {
  inject: ["reload"],
  data() {
    return {
      print_no_inspect: 1,
      isprint: 1,
      isDegree: false,
      degree: "", //配合程度
      config: {},
      downWords: false,
      aaaa: "page-break-before:always",
      tmp: {},
      form: {},
      SetReportBox: false,
      setReportForm: [],
      fullscreenLoading: true,
      userReporData: {
        customer: {
          id: 1,
          account: "iEfoam",
          name: "iEfoam",
          sex: "男",
          birthday: "1997-05-29",
          department_id: 1,
          unit_name: "重庆医科大学附属第二医院",
          department_name: "技术部门",
          age: 22,
        },
        measure_title: "心理健康临床症状自评量表(SCL-90)",
        theme: 3,
        base_results: {},
        warning: 0,
        factor_results: [],
      },
      uid: {},
      charDataList: [],
      charDataList2: [],
      charNameList: [],
      // ----------新--------------------
      // 图表数据
      chartDataList: [],
      // 表数据
      tableInfoList: [],
      listData1: [], //数值
      listData2: [], //参考值
      listData3: [], //图示名
      fontSize: "16px", // 字体大小
      wordImgUrl: [], //图标base64
      signatureImg: [], //签名base64
      // 编辑状态
      editState: false,
      // 等待下载
      downLoad: false,
      downLoadNum: 0,
      downLoadTitle: "",
      //zip数据
      zipData: null,
      referenceShowObj:{
        "儿童抑郁障碍自评量表(DSRSC)":true,
        "儿童焦虑性情绪障碍筛查表（SCARED)":true,
      }
    };
  },
  beforeCreate() {},
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // console.log("单");
      this.isDegree = false; // 配合程度
      data.id = this.$route.query.id;
      this.uid.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // console.log("多");
      data.id = this.$route.query.ids;
      this.uid.ids = this.$route.query.ids;
      // console.log(this.$route.query.ids.split(",").length == 1);
      if (this.$route.query.ids.split(",").length == 1) {
        this.isDegree = false;
      }
    }
    // 发起请求渲染报告
    loadReport(data).then((res) => {
      this.print_no_inspect = res.print_no_inspect * 1;
      if(Array.isArray(res.data)){
       this.isprint = res.data[0].inspect * 1;
      }else{
        this.isprint = res.data.inspect * 1;;
      }
    
      this.tmp = JSON.parse(JSON.stringify(res.config)); //获取-全局配置信息
      // 判断是管理员还是用户端
      if (this.tmp.user_scope_basic) {
        this.form.scope_assessor = res.config.user_scope_assessor;
        this.form.scope_basic = res.config.user_scope_basic;
        this.form.scope_explain = res.config.user_scope_explain;
        this.form.scope_factor = res.config.user_scope_factor;
        this.form.scope_graph = res.config.user_scope_graph;
        this.form.scope_score = res.config.user_scope_score;
        this.form.scope_suggest = res.config.user_scope_suggest;
        this.form.scope_question = res.config.user_scope_question;
      } else {
        this.form = res.config; //打印设置
      }
      let tmp = 0;
      let infoTmp = [];
      // 判断单个还是多个量表
      if (this.$route.query.ids) {
        tmp = this.$route.query.ids.split(",").length;
      }
      if (this.$route.query.id || tmp < 2) {
        this.tableInfoList = [res.data];
        infoTmp = [res.data];
      } else {
        this.tableInfoList = res.data;
        infoTmp = res.data;
      }

      // 1-图表数据处理
      let resArr = [];
      let imgBase64 = [];
      this.tableInfoList.forEach((v, k) => {
        // --------------------------- 签名图片处理--------------------
        this.convertImgToBase64(v.admin.sign_img, function (base64Img) {
          imgBase64.push(base64Img);
        });
        // --------------------------- 单独处理数据--------------------
        // 1.明尼苏达多相个性测查表-399
        if (v.initial_measure_title == "明尼苏达多相个性测查表-399") {
          // console.log(v.initial_measure_title);
          return false;
        }
        if (v.initial_measure_title == "明尼苏达多相个性测查表") {
          // console.log(v.initial_measure_title);
          return false;
        }
        // 阳性与阴性症状量表
        if (v.initial_measure_title == "阳性与阴性症状量表(PANSS)") {
          v.factor_results = v.factor_results.slice(18, 24);
        }

        // -----------------------------------共用报告数据处理------------------------------------------------
        let thisArr = [];
        let list1 = [];
        let list2 = [];
        let list3 = [];
        // -----.base_results 添加至 数组 .factor_results----
        //          true --- false                 true
        if (!(v.base_results instanceof Array) && v.base_results != {}) {
          this.tableInfoList[k].factor_results.unshift(v.base_results);
        } else if (v.factor_results.length == 0) {
          this.tableInfoList[k].factor_results.unshift(v.base_results);
        }

        v.factor_results.forEach((vs, ks) => {
          if (vs.graph_show != 0) {
            // -------------数据1，数据2---------------
            if (vs.score != "-") {
              //  ------------最大值和x坐标标记---------------
              let xArr = {};
              xArr["name"] = vs.name;
              xArr["max"] = vs.score * 1 + 0.5;
              thisArr[ks] = xArr;
              list1.push(vs.score);
              list3.push(vs.name);
            }
            if (v.reference) {
              list2.push(v.reference.split("-")[1]);
            }
          }
          // ------------------图示----------------------
        });

        resArr[k] = thisArr;
        this.listData1[k] = list1;
        this.listData2[k] = list2;
        this.listData3[k] = list3;
        // ----------------------- 兼容处理 --------------------------------
        // ------------1- 解决 程度 不存在 的数据 但是要渲染图中显示因子--------------
        for (let i = 0; i < this.tableInfoList[k].factor_results.length; i++) {
          this.tableInfoList[k].factor_results = this.tableInfoList[
            k
          ].factor_results.filter((v) => {
            return v.mark != "";
          });
        }
      });
      this.chartDataList = resArr;
      this.signatureImg = imgBase64;
      // 1- 解决 程度 不存在 的数据 但是要渲染图中显示因子
      // infoTmp.forEach((v, k) => {});
    });
  },
  mounted() {
    // this.charData();
    this.$nextTick(() => {
      // this.charData();
      // 字体大小设置
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // ----------------------
    });
  },
  methods: {
    
    connotPrint() {
      // debugger
      if (!this.print_no_inspect) {
        //未审核不能打印
        if (this.isprint) {
          let { href } = this.$router.resolve({
            path: "/downpdf",
            query: this.uid,
          });
          window.open(href, "_blank");
          // this.$router.push({ path: "/downpdf", query: this.uid });
        } else {
          this.$message({
            type: "error",
            message: "未审核不能打印",
          });
        }
      } else {
        let { href } = this.$router.resolve({
          path: "/downpdf",
          query: this.uid,
        });
        window.open(href, "_blank");
      }
    },
    // 审核
    upInspect() {
      let id = this.$route.query.id;
      this.$confirm("是否审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          inspectUp({ id }).then((res) => {
            if (res.code == 400200) {
              this.isprint = 1;
              this.$message({
                type: "success",
                message: res.msg,
              });
              // row.inspect = 1;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // 重置数据
    resc() {
      this.form = JSON.parse(JSON.stringify(this.tmp));
    },
    // 保持打印设置
    onSubmit() {
      updateConfig(this.form).then((res) => {
        if (res.code == 400200) {
          this.SetReportBox = false;
          this.form = this.form;
          this.tmp = JSON.parse(JSON.stringify(this.form));
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
        }
      });
    },
    // 控制打印设置
    showSetReportBox() {
      this.SetReportBox = true;
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    handleTagReference(ferenceData,num){
      console.log(ferenceData,num,"ferenceData")
      let newrangeList = [];
      let retStr = "";
      try{
        newrangeList = ferenceData.split(';')
        for(let i=0;i<newrangeList.length;i++){
          let item = newrangeList[i];
          console.log(item,"num")
          if(!isNaN(item) && !isNaN(parseFloat(item))){
          }else{
            let list1 = item.split(":");
            if(list1[0]){
              let list = list1[0].replace('[', '').replace(')', '').replace(']', '').split('-');
              console.log(list1[0],"list1[0]");
              if(num>=list[0]&&num<=list[1]){
                retStr = `（${list[0]}-${list[1]} 为${list1[1]}）`;
                break
              }
            }   
          }
        }
      }catch{};
      return retStr
    },
    //   图表1
    charData() {
      for (let i = 0; i < this.tableInfoList.length; i++) {
        // --------基于准备好的dom，初始化echarts实例---------
        let ageData = [];
        ageData[i] = this.$echarts.init(
          document.getElementById(`chart-report${i}`)
        );
        this.myChart = ageData;
        //---------------- 处理特殊量表报告-----------------
        if (
          this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表-399" ||
          this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表"
        ) {
          //  图表数据处理
          let xName = []; //x轴 -下
          let x2Name = []; //x轴 -上
          let yData = [];

          for (let k1 in this.tableInfoList[i].results.mmpi) {
            // 图中数据
            // kCmCore 加k
            if (k1 == "kNoCmCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                if (this.tableInfoList[i].results.mmpi[k1][k2] == "") {
                  yData.push(0);
                } else {
                  yData.push(this.tableInfoList[i].results.mmpi[k1][k2]);
                }
              }
            }
            if (k1 == "oldCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                xName.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
            if (k1 == "help") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                x2Name.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
          }
          let yMax = Math.max.apply(null, yData) + 10;
          // echart 图表创建
          ageData[i].setOption({
            animation: false,
            backgroundColor: "white",
            title: {
              text: "基本量表剖析图(按中国常模,不加 K 校正)",
              x: "center",
              top: -5,
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "2%",
              top: "10%",
              containLabel: true,
            },
            xAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                  interval: 0,
                },
                type: "category",
                boundaryGap: ["30%", "20%"],
                name: "T-C",
                nameTextStyle: { fontSize: 14, padding: [40, 0, 10, 0] },
                nameLocation: "start",

                data: xName,
              },
              {
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                  interval: 0,
                },
                data: x2Name,
              },
            ],
            yAxis: [
              {
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                },
                axisLine: { show: false },
                type: "value",
                interval: 20,
                min: 0,
                max: yMax,
              },
            ],
            series: [
              {
                name: "accuracy",
                type: "line",
                itemStyle: {
                  normal: {
                    color: "#003366",
                    lineStyle: { color: "#003366" },
                    label: { show: true },
                  },
                },
                symbol: "star",
                symbolSize: 8,
                markPoint: {
                  symbolSize: 100,

                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        fontSize: 20,
                      },
                    },
                  },
                },
                //itemStyle:{ normal: {label : {show: true}}},
                xAxisIndex: 1,
                data: yData,
              },
            ],
          });
          // 转出ba64格式图片
          let imgURL = ageData[i].getDataURL({
            pixelRatio: 1,
          });
          this.wordImgUrl.push(imgURL);
          // 开始下次循环
          continue;
        }
        // ---------------处理通用量表报告--------------------
        let data1 = this.listData1[i];
        let data2 = this.listData2[i];
        let data3 = this.listData3[i];
        //  数据处理
        let str = this.chartDataList[i].filter((val, index, arr) => {
          if (
            !(this.tableInfoList[i].base_results instanceof Array) &&
            this.tableInfoList[i].base_results != {}
          ) {
            return index !== 0;
          } else {
            return index !== -1;
          }
        });
        // DISC性格测试 str 数据兼容处理
        if (this.tableInfoList[i].measure_title == "DISC性格测试") {
          data3 = ["支配", "影响", "稳健", "服从"];
        }
        // name 和 num 数据组合
        let arr = [];
        data1.forEach((v, k) => {
          let obj = {
            value: v,
            name: data3[k],
          };
          arr.push(obj);
        });
        let arr2 = [
          { name: this.listData3[i][0], value: this.listData1[i][0] },
        ];
        // 最大值
        let dataMax = Math.max(...data1.map(Number));
        if (
          this.tableInfoList[i].reference * 1 &&
          this.tableInfoList[i].reference * 1 > dataMax * 1
        ) {
          dataMax = this.tableInfoList[i].reference * 1;
        }
        // reference
        // if (
        //   this.tableInfoList[i].base_results.length ||
        //   this.tableInfoList[i].base_results.reference
        // ) {
        //   dataMax = this.tableInfoList[i].base_results.reference * 1;
        // }
        // if (this.tableInfoList[i].base_results.reference == "") {
        //   dataMax = Math.max(...data1.map(Number));
        // }
        if (this.tableInfoList[i].theme == -1) {
          break;
        }

        // 绘制图表-----------
        // 7：图标颜色方法
        var axislineColor = new this.$echarts.graphic.LinearGradient(
          0,
          0,
          1,
          0,
          [
            {
              offset: 0,
              color: "#87F3ED",
            },
            {
              offset: 0.5,
              color: "#A5B8FF",
            },

            {
              offset: 1,
              color: "#FF0000",
            },
          ]
        );
        //  2，3 多数据折线
        //  5,6  多数据圆形
        //  1、4、7 、8 单数据-仪表盘
        //  9 10 多数据柱状图

        if (this.tableInfoList[i].theme == 3) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "transparent",
            // backgroundColor: "red",
            normal: {
              top: 200,
              left: 300,
              width: 500,
              height: 400,
              zIndex: 6,
              backgroundColor: "",
            },
            color: ["rgba(245, 166, 35, 1)", "rgba(19, 173, 255, 1)"],
            tooltip: {
              show: true,
              trigger: "item",
            },
            legend: {
              show: true,
              icon: "circle",
              left: "center",
              top: "92%",
              orient: "horizontal",
              textStyle: {
                fontSize: 14,
                color: "black",
              },
              data: ["得分"],
            },
            radar: {
              center: ["50%", "50%"],
              radius: "70%",
              startAngle: 90,
              splitNumber: 4,
              shape: "circle",
              splitArea: {
                areaStyle: {
                  color: ["transparent"],
                },
              },
              axisLabel: {
                show: false,
                fontSize: 18,
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "normal",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "grey", //
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "grey", //
                },
              },
              indicator: str,

              // [
              //   {
              //     name: "道路结冰",
              //     max: 88
              //   },
              // ]
            },
            series: [
              {
                name: "得分",
                type: "radar",
                symbol: "circle",
                symbolSize: 10,
                areaStyle: {
                  normal: {
                    color: "rgba(245, 166, 35, 0.4)",
                  },
                },
                itemStyle: {
                  color: "rgba(245, 166, 35, 1)",
                  borderColor: "rgba(245, 166, 35, 0.3)",
                  borderWidth: 10,
                },
                lineStyle: {
                  normal: {
                    type: "dashed",
                    color: "rgba(245, 166, 35, 1)",
                    width: 2,
                  },
                },
                data: data1.length == 0 ? [] : [data1],
                label: {
                  normal: {
                    show: true,
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "rgba(128,128,128)",
                    position: "top",
                  },
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 1) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            title: [
              {
                x: "center",
                bottom: 30,
                text: this.tableInfoList[i].factor_results[0].name,
                textStyle: {
                  fontWeight: "normal",
                  fontSize: 20,
                  color: "black",
                },
              },
            ],
            tooltip: {
              show: true,
            },
            series: [
              {
                type: "gauge",
                center: ["50%", "55%"], // 默认全局居中
                radius: "80%",
                splitNumber: 10, //刻度数量
                min: 0,
                max: dataMax,
                startAngle: 200,
                endAngle: -20,
                clockwise: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    shadowBlur: 0,
                    color: [[1, "#03B7C9"]],
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {
                    color: "#03B7C9",
                    width: 1,
                  },
                  length: -15,
                  splitNumber: 5,
                },
                splitLine: {
                  show: true,
                  length: -25,
                  lineStyle: {
                    color: "#03B7C9",
                  },
                },
                axisLabel: {
                  distance: -20,
                  textStyle: {
                    color: "#03B7C9",
                    fontSize: "15",
                    fontWeight: "bold",
                  },
                },
                pointer: {
                  //仪表盘指针
                  show: 0,
                },
                detail: {
                  show: false,
                },
                data: [
                  {
                    name: "",
                    value: 100,
                  },
                ],
              },
              {
                startAngle: 200,
                endAngle: -20,
                type: "gauge",
                center: ["50%", "55%"], // 默认全局居中
                radius: "70%",
                min: 0,
                max: 1000,
                splitNumber: 0,
                axisLine: {
                  // 坐标轴线
                  lineStyle: {
                    color: [
                      [0.66, "#dddddd"],
                      [1, "#dddddd"],
                    ], // 属性lineStyle控制线条样式
                    width: 4,
                  },
                },

                axisLabel: {
                  // 坐标轴小标记
                  textStyle: {
                    // 属性lineStyle控制线条样式
                    fontWeight: "bolder",
                    fontSize: 16,
                    color: "rgba(30,144,255,0)",
                  },
                },
                splitLine: {
                  // 分隔线
                  length: 40, // 属性length控制线长
                  lineStyle: {
                    // 属性lineStyle（详见lineStyle）控制线条样式
                    width: 0,
                    color: "#444",
                  },
                },
                pointer: {
                  // 分隔线 指针
                  color: "#666666",
                  width: 0,
                  length: 400,
                },
                detail: {
                  show: false,
                },
              },
              {
                name: this.tableInfoList[i].factor_results[0].name,
                type: "gauge",
                startAngle: 200,
                endAngle: -20,
                radius: "80%",
                center: ["50%", "55%"], // 默认全局居中
                min: 0,
                max: dataMax,
                axisLine: {
                  show: false,
                  lineStyle: {
                    width: 25,
                    shadowBlur: 0,
                    color: [
                      [0.2, "#00FAFC"],
                      [0.4, "#3BD542"],
                      [0.6, "#E3F424"],
                      [0.8, "#7E48DA"],
                      [1, "red"],
                    ],
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                  length: 20,
                },

                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: true,
                },
                detail: {
                  show: true,
                  offsetCenter: [0, "40%"],
                  textStyle: {
                    fontSize: 30,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#03B7C9",
                  },
                },
                data: [
                  {
                    value: this.listData1[i][0],
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 2) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#ffffff",
            legend: {
              right: 20,
              top: 20,
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: [
              {
                type: "category",
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                splitArea: {
                  color: "#f00",
                  lineStyle: {
                    color: "#f00",
                  },
                },
                axisLabel: {
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                splitLine: {
                  show: false,
                },
                boundaryGap: false,
                data: data3,
              },
            ],

            yAxis: [
              {
                nameTextStyle: {
                  color: "#6c50f3",
                  fontSize: 14,
                },
                type: "value",
                max: dataMax,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "rgba(255,255,255,0.1)",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                axisLabel: {
                  show: true,
                  margin: 20,
                  textStyle: {
                    color: "#6c50f3",
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
              },
            ],
            series: [
              {
                type: "line",
                smooth: true,
                showAllSymbol: true,
                symbol: "circle",
                symbolSize: 15,
                lineStyle: {},
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#6c50f3",
                  },
                },
                itemStyle: {
                  color: "#6c50f3",
                  borderColor: "#fff",
                  borderWidth: 3,
                  shadowColor: "rgba(0, 0, 0, .3)",
                  shadowBlur: 0,
                  shadowOffsetY: 2,
                  shadowOffsetX: 2,
                },
                tooltip: {
                  show: false,
                },
                areaStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "rgba(108,80,243,0.3)",
                        },
                        {
                          offset: 1,
                          color: "rgba(108,80,243,0)",
                        },
                      ],
                      false
                    ),
                    shadowColor: "rgba(108,80,243, 0.9)",
                    shadowBlur: 20,
                  },
                },
                data: data1,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 4) {
          let value = this.listData1[i][0];
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            title: {
              z: 999,
              text: `${value}分`,
              // subtext: this.tableInfoList[i].factor_results[0].name,
              left: "center",
              top: "center", //top待调整
              textStyle: {
                color: "#fff",
                fontSize: 60,
                fontFamily: "DINAlternate-Bold",
              },
              subtextStyle: {
                color: "#ff",
                fontSize: 35,
                fontFamily: "PingFangSC-Regular",
                top: "center",
              },
              itemGap: -4, //主副标题间距
            },
            xAxis: {
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              // data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            },
            yAxis: {
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
            },
            series: [
              // 进度圈
              {
                type: "pie",
                clockWise: true,
                radius: ["60%", "55%"],
                data: [
                  {
                    value: value,
                    itemStyle: {
                      normal: {
                        borderWidth: 10,
                        borderColor: {
                          colorStops: [
                            {
                              offset: 0,
                              color: "transparent" || "transparent", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "transparent" || "transparent", // 100% 处的颜色
                            },
                          ],
                        },
                        color: {
                          // 完成的圆环的颜色
                          colorStops: [
                            {
                              offset: 0,
                              color: "transparent" || "transparent", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "transparent" || "transparent", // 100% 处的颜色
                            },
                          ],
                        },
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false,
                        },
                      },
                    },
                  },
                  {
                    name: "gap",
                    value: 100 - value,
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false,
                        },
                        color: "rgba(0, 0, 0, 0)",
                        borderColor: "rgba(0, 0, 0, 0)",
                        borderWidth: 0,
                      },
                    },
                  },
                ],
              },
              //刻度尺
              {
                // name: "白色圈刻度",
                type: "gauge",
                radius: "75%",
                startAngle: 225, //刻度起始
                endAngle: -134.8, //刻度结束
                z: 4,
                axisTick: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    color: "rgba(1,244,255, 0.9)",
                  },
                },
                splitLine: {
                  length: 16, //刻度节点线长度
                  lineStyle: {
                    width: 2,
                    color: "rgba(1,244,255, 0.9)",
                  }, //刻度节点线
                },
                axisLabel: {
                  color: "rgba(255,255,255,0)",
                  fontSize: 12,
                }, //刻度节点文字颜色
                pointer: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    opacity: 0,
                  },
                },
                detail: {
                  show: false,
                },
                data: [
                  {
                    value: 0,
                    name: "",
                  },
                ],
              },
              //最外层圈
              {
                type: "pie",
                radius: ["0%", "95%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                hoverAnimation: false,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      fontWeight: "bold",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      // 外
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(63,245,175)",
                        },

                        {
                          offset: 1,
                          color: "rgb(128,250,120)",
                        },
                      ],
                    },
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                data: [
                  {
                    value: 3235,
                  },
                ],
              },
              // 刻度圈
              {
                type: "pie",
                radius: ["0%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      fontWeight: "bold",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      // 中
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgb(187,249,255)",
                        },

                        {
                          offset: 1,
                          color: "rgb(243,249,251)",
                        },
                      ],
                    },
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                data: [
                  {
                    value: 3235,
                  },
                ],
              },
              // 内圆
              {
                type: "pie",
                radius: ["0", "50%"],
                center: ["50%", "50%"],
                z: 99,
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      // 内
                      [
                        {
                          offset: 0,
                          color: "rgb(97,225,250)",
                        },

                        {
                          offset: 1,
                          color: "rgb(61,183,255)",
                        },
                      ]
                    ),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
                label: {
                  normal: {
                    position: "center",
                  },
                },
                data: [100],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 5) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            color: [
              "#EAEA26",
              "#906BF9",
              "#FE5656",
              "#01E17E",
              "#3DD1F9",
              "#FFAD05",
            ],
            // title: {
            //     text: '网络/安全设备',
            //     left: '60',
            //     top: 0,
            //     textAlign: 'center',
            //     textStyle: {
            //         color: '#fff',
            //         fontSize: 14,
            //         fontWeight: 0
            //     }
            // },
            grid: {
              left: -100,
              top: 50,
              bottom: 10,
              right: 10,
              containLabel: true,
            },
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },
            legend: {
              type: "scroll",
              orient: "vartical",
              // x: "right",
              top: "center",
              right: "15",
              // bottom: "0%",
              itemWidth: 16,
              itemHeight: 8,
              itemGap: 16,
              textStyle: {
                color: "#A3E2F4",
                fontSize: 12,
                fontWeight: 0,
              },
              data: data3,
            },
            polar: {},
            angleAxis: {
              interval: 1,
              type: "category",
              data: [],
              z: 10,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B4A6B",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                interval: 0,
                show: true,
                color: "#0B4A6B",
                margin: 8,
                fontSize: 16,
              },
            },
            radiusAxis: {
              min: 40,
              max: 120,
              interval: 20,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B3E5E",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                formatter: "{value} %",
                show: false,
                padding: [0, 0, 20, 0],
                color: "#0B3E5E",
                fontSize: 16,
              },
              splitLine: {
                lineStyle: {
                  color: "#0B3E5E",
                  width: 2,
                  type: "solid",
                },
              },
            },
            calculable: true,
            series: [
              {
                type: "pie",
                radius: ["5%", "10%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                type: "pie",
                radius: ["90%", "95%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                name: "",
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                stack: "a",
                type: "pie",
                radius: ["20%", "80%"],
                roseType: "area",
                zlevel: 10,
                label: {
                  normal: {
                    show: true,
                    formatter: "{c}",
                    textStyle: {
                      fontSize: 12,
                    },
                    position: "outside",
                  },
                  emphasis: {
                    show: true,
                  },
                },
                labelLine: {
                  normal: {
                    show: true,
                    length: 20,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                data: arr,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 6) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },

            visualMap: {
              show: false,
              min: 500,
              max: 600,
              inRange: {
                //colorLightness: [0, 1]
              },
            },
            series: [
              {
                name: "访问来源",
                type: "pie",
                radius: "60%",
                center: ["50%", "50%"],
                color: [
                  "rgb(255,159,64)",
                  "rgb(178,34,34)",
                  "rgb(255,205,86)",
                  "rgb(75,192,192)",
                  "rgb(54,162,235)",
                  "rgb(84,120,228)",
                  "rgb(121,106,210)",
                  "rgb(32,66,101)",
                  "rgb(140,255,25)",
                  "rgb(255,97,3)",
                ], //'#FBFE27','rgb(11,228,96)','#FE5050'
                data: arr.sort(function (a, b) {
                  return a.value - b.value;
                }),
                roseType: "radius",

                label: {
                  normal: {
                    formatter: ["{c|{c}分}", "{b|{b}}"].join("\n"),
                    rich: {
                      c: {
                        color: "black",
                        fontSize: 14,
                        fontWeight: "bold",
                        lineHeight: 5,
                      },
                      b: {
                        color: "rgb(98,137,169)",
                        fontSize: 14,
                        height: 30,
                      },
                    },
                  },
                },
                labelLine: {
                  normal: {
                    lineStyle: {
                      color: "rgb(98,137,169)",
                    },
                    smooth: 0.2,
                    length: 20,
                    length2: 10,
                  },
                },
                itemStyle: {
                  normal: {
                    shadowColor: "rgba(0, 0, 0, 1)",
                  },
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 7) {
          ageData[i].setOption({
            animation: false,
            series: [
              {
                name: "车辆总数",
                type: "gauge",
                z: 3,
                min: 0,
                max: dataMax,
                splitNumber: 10,
                radius: "80%",
                axisLine: {
                  // 坐标轴线
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    width: 21,
                    color: [[1, axislineColor]],
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  distance: -55,
                  textStyle: {
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500,
                  },
                },
                splitLine: {
                  // 分隔线
                  show: false,
                },
                title: {
                  offsetCenter: [0, 18],
                  textStyle: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    color: "white",
                    fontSize: 20,
                  },
                },
                detail: {
                  offsetCenter: [0, 100],
                  textStyle: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    color: "#007FFF",
                    fontSize: 40,
                    fontWeight: 500,
                  },
                  formatter: function (value) {
                    return `${value}分`;
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#8492AA",
                  },
                },
                data: arr2,
              },
              {
                name: "",
                type: "gauge",
                z: 2,
                min: 0,
                max: dataMax,
                splitNumber: 10,
                radius: "70%",
                axisLine: {
                  // 坐标轴线
                  show: false,
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    width: 0,
                    color: [[1, axislineColor]],
                  },
                },
                title: {
                  show: false,
                },
                detail: {
                  show: false,
                },
                axisTick: {
                  show: true,
                  length: 15,
                  lineStyle: {
                    width: 3,
                    color: "#979797",
                  },
                },
                axisLabel: {
                  show: false,
                },
                splitLine: {
                  // 分隔线
                  show: false,
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 8) {
          console.log("arr", arr2);

          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            tooltip: {
              position: "inside",
              formatter:
                "小于20%有发展空间<br>20%~40%为健康<br>40%~60%为预警<br>大于60%为过度",
              textStyle: {
                fontSize: 10,
              },
            },
            series: [
              {
                name: "刻度",
                type: "gauge",
                center: ["50%", "70%"],
                radius: "90%",
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 8, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: [[1, "rgba(0,0,0,0)"]],
                  },
                }, //仪表盘轴线
                axisLabel: {
                  show: true,
                  color: "#050505",
                  fontSize: 15,
                  distance: -50,
                  formatter: function (v) {
                    return v;
                  },
                }, //刻度标签。
                axisTick: {
                  show: true,
                  splitNumber: 5,
                  lineStyle: {
                    color: "#050505",
                    width: 1,
                  },
                  length: -3,
                }, //刻度样式
                splitLine: {
                  show: true,
                  length: -5,
                  lineStyle: {
                    color: "#050505",
                  },
                }, //分隔线样式
              },
              {
                type: "gauge",
                radius: "80%",
                center: ["50%", "70%"],
                splitNumber: 0, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 50,
                    color: [
                      [0.52, "#00a65a"],
                      [0.63, "#EEC900"],
                      [0.72, "#ff6600"],
                      [1, "#ff0000"],

                      // [0.4, "#2b64fc"],
                      // [0.6, "#f39c11"],
                      // [1, "#fa4e4b"]
                    ],
                  },
                },
                //分隔线样式。
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                pointer: {
                  show: true,
                  length: "60%",
                  width: "7%",
                },
                title: {
                  show: false,
                  offsetCenter: [0, "60%"], // x, y，单位px
                  textStyle: {
                    fontWeight: "bold",
                    color: "#050505",
                    fontSize: 30,
                  },
                },
                //仪表盘详情，用于显示数据。
                detail: {
                  show: true,
                  offsetCenter: [0, "30%"],
                  color: "#ffffff",

                  formatter: function (value) {
                    if (value <= 20) {
                      return "有发展空间";
                    } else if (value <= 40) {
                      return "健康";
                    } else if (value <= 60) {
                      return "预警";
                    } else {
                      return "过度";
                    }
                  },
                  textStyle: {
                    fontSize: 30,
                  },
                },
                data: arr2,
              },
              {
                // 仅显示tooltip用
                type: "pie",
                radius: "90%",
                hoverAnimation: true,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                z: 3,
                zlevel: 0,
                itemStyle: {
                  borderWidth: 0,
                },
                data: [
                  {
                    value: 50,
                    name: "",
                    label: {
                      normal: {
                        show: false,
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: "transparent",
                      },
                    },
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 88) {
          console.log(this.tableInfoList[i]);
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            tooltip: {
              position: "inside",
              formatter:
                "小于20%有发展空间<br>20%~40%为健康<br>40%~60%为预警<br>大于60%为过度",
              textStyle: {
                fontSize: 10,
              },
            },
            series: [
              {
                name: "刻度",
                type: "gauge",
                center: ["50%", "70%"],
                radius: "90%",
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 8, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: [[1, "rgba(0,0,0,0)"]],
                  },
                }, //仪表盘轴线
                axisLabel: {
                  show: true,
                  color: "#050505",
                  fontSize: 20,
                  distance: -50,
                  formatter: function (v) {
                    return v;
                  },
                }, //刻度标签。
                axisTick: {
                  show: true,
                  splitNumber: 5,
                  lineStyle: {
                    color: "#050505",
                    width: 1,
                  },
                  length: -3,
                }, //刻度样式
                splitLine: {
                  show: true,
                  length: -5,
                  lineStyle: {
                    color: "#050505",
                  },
                }, //分隔线样式
              },
              {
                type: "gauge",
                radius: "80%",
                center: ["50%", "70%"],
                splitNumber: 0, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 50,
                    color: [
                      [0.49, "#00a65a"],
                      [0.59, "#EEC900"],
                      [0.69, "#ff6600"],
                      [1, "#ff0000"],

                      // [0.4, "#2b64fc"],
                      // [0.6, "#f39c11"],
                      // [1, "#fa4e4b"]
                    ],
                  },
                },
                //分隔线样式。
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                pointer: {
                  show: true,
                  length: "60%",
                  width: "7%",
                },
                title: {
                  show: false,
                  offsetCenter: [0, "60%"], // x, y，单位px
                  textStyle: {
                    fontWeight: "bold",
                    color: "#050505",
                    fontSize: 30,
                  },
                },
                //仪表盘详情，用于显示数据。
                detail: {
                  show: true,
                  offsetCenter: [0, "30%"],
                  color: "#ffffff",

                  formatter: function (value) {
                    if (value <= 20) {
                      return "有发展空间";
                    } else if (value <= 40) {
                      return "健康";
                    } else if (value <= 60) {
                      return "预警";
                    } else {
                      return "过度";
                    }
                  },
                  textStyle: {
                    fontSize: 30,
                  },
                },
                data: arr2,
              },
              {
                // 仅显示tooltip用
                type: "pie",
                radius: "90%",
                hoverAnimation: true,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                z: 3,
                zlevel: 0,
                itemStyle: {
                  borderWidth: 0,
                },
                data: [
                  {
                    value: 50,
                    name: "",
                    label: {
                      normal: {
                        show: false,
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: "transparent",
                      },
                    },
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 9) {
          var max9 = "";
          data3.forEach((v) => {
            if (v.length > max9.length) {
              max9 = v;
            }
          });
          var xp = max9.length * 3;
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            grid: {
              top: "10%",
              right: "10%",
              left: "10%",
              bottom: xp + "%",
            },
            xAxis: [
              {
                type: "category",
                color: "#59588D",
                data: data3,
                axisLabel: {
                  rotate: 50,
                  margin: 10,
                  color: "#999",
                  textStyle: {
                    fontSize: 12,
                  },
                },
                axisLine: {
                  lineStyle: {
                    color: "rgba(107,107,107,0.37)",
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            yAxis: [
              {
                min: 0,
                max: dataMax,
                axisLabel: {
                  formatter: "{value}",
                  color: "#999",
                  textStyle: {
                    fontSize: 18,
                  },
                },
                axisLine: {
                  lineStyle: {
                    color: "rgba(107,107,107,0.37)",
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  lineStyle: {
                    color: "rgba(131,101,101,0.2)",
                    type: "dashed",
                  },
                },
              },
            ],
            series: [
              {
                type: "bar",
                data: data1,
                barWidth: "30px",
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#FF9A22", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FFD56E", // 100% 处的颜色
                        },
                      ],
                      false
                    ),
                    barBorderRadius: [30, 30, 0, 0],
                  },
                },
                label: {
                  normal: {
                    show: true,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#333",
                    position: "top",
                  },
                },
              },
              {
                data: data1,
                type: "line",
                smooth: true,
                name: "折线图",
                symbol: "none",
                lineStyle: {
                  color: "#3275FB",
                  width: 4,
                  shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
                  shadowBlur: 15,
                  shadowOffsetY: 20,
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 10) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            grid: {
              left: "12%",
              top: "10%",
              bottom: "20%",
              right: "8%",
            },
            xAxis: {
              data: data3,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 129, 109, 0.1)",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              axisLabel: {
                rotate: 30,
                textStyle: {
                  color: "black",
                  fontSize: 14,
                },
              },
            },
            yAxis: [
              {
                min: 0,
                max: dataMax,
              },
              {
                splitNumber: 2,
                axisTick: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    color: "black",
                    width: 1, //这里是为了突出显示加上的
                  },
                },
                axisLabel: {
                  textStyle: {
                    color: "black",
                  },
                },
                splitArea: {
                  areaStyle: {
                    color: "rgba(255,255,255,.5)",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "black",
                    width: 0.5,
                    type: "dashed",
                  },
                },
              },
            ],
            series: [
              {
                name: "hill",
                type: "pictorialBar",
                barCategoryGap: "0%",
                symbol:
                  "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
                label: {
                  show: true,
                  position: "top",
                  distance: 15,
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 16,
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(232, 94, 106, .8)", //  0%  处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(232, 94, 106, .1)", //  100%  处的颜色
                        },
                      ],
                      global: false, //  缺省为  false
                    },
                  },
                  emphasis: {
                    opacity: 1,
                  },
                },
                data: data1,
                z: 10,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 11) {
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            color: [
              "rgb(255,159,64)",
              "rgba(178,34,34,0.7)",
              "rgba(255,205,86,0.7)",
              "rgba(75,192,192,0.7)",
              "rgba(54,162,235,0.7)",
              "rgba(84,120,228,0.7)",
              "rgba(121,106,210,0.7)",
              "rgba(32,66,101,0.7)",
              "rgba(140,255,25,0.7)",
              "rgba(255,97,3,0.7)",
            ],
            grid: {
              left: -100,
              top: 10,
              bottom: 10,
              right: 10,
              containLabel: true,
            },
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },
            legend: {
              type: "scroll",
              orient: "vartical",
              // x: "right",
              top: "center",
              right: "15",
              // bottom: "0%",
              itemWidth: 16,
              itemHeight: 8,
              itemGap: 16,
              textStyle: {
                color: "black",
                fontSize: 12,
                fontWeight: 0,
              },
              data: data3,
            },
            polar: {
              center: ["35%", "50%"],
            },
            angleAxis: {
              interval: 1,
              type: "category",
              data: [],
              z: 10,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B4A6B",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                interval: 0,
                show: true,
                color: "#0B4A6B",
                margin: 8,
                fontSize: 16,
              },
            },
            radiusAxis: {
              min: 0,
              max: Math.ceil(dataMax),
              interval: Math.ceil(dataMax / 5),
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#black",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                formatter: "{value} 分",
                show: true,
                padding: [0, 0, 10, 0],
                color: "black",
                fontSize: 10,
              },
              splitLine: {
                lineStyle: {
                  color: "#black",
                  width: 1,
                  type: "solid",
                },
              },
            },
            calculable: true,
            series: [
              {
                type: "pie",
                radius: ["50%", "50%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                name: "",
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                stack: "a",
                type: "pie",
                radius: ["0%", "80%"],
                center: ["35%", "50%"],
                roseType: "area",
                zlevel: 1,
                itemStyle: {
                  //图形样式
                  normal: {
                    borderColor: "rgba(255,255,255,0.5)",
                    borderWidth: 6,
                  },
                },
                label: {
                  normal: {
                    show: true,
                    formatter: "{b}-{c}",
                    textStyle: {
                      fontSize: 14,
                      color: "black",
                    },
                    position: "outside",
                  },
                  emphasis: {
                    show: true,
                  },
                },
                labelLine: {
                  normal: {
                    lineStyle: {
                      color: "black",
                    },
                    length: 20,
                    length2: 10,
                  },
                },
                data: arr,
              },
            ],
          });
        } else {
          // 获取最大值
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#FFFFFF",
            xAxis: {
              type: "category",
              data: data3,
              axisLabel: {
                interval: 0,
                rotate: 15,
                show: true,
                splitNumber: 15,
                textStyle: {
                  fontFamily: "微软雅黑",
                  fontSize: 10,
                },
              },
            },
            yAxis: {
              type: "value",
              // max: 300
              max: Math.ceil(dataMax * 1.5),
            },
            grid: {
              bottom: 100,
            },
            dataZoom: {
              height: 15,
              type: "slider", //图表下方的伸缩条
              show: false, //是否显示
              realtime: true,
              start: 1,
              end: 100,
            },
            series: [
              {
                data: data1,
                type: "bar",
                name: "柱状图",
                barWidth: 15,
                min: 0,
                // max: 200,
                itemStyle: {
                  normal: {
                    //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                    color: function (params) {
                      var colorList = [
                        "rgb(102,186,248)",
                        "rgb(35,150,231)",
                        "rgb(119,115,189)",
                      ];
                      return colorList[params.dataIndex % colorList.length];
                    },
                  },
                },
              },
              {
                data: data1,
                type: "line",
                name: "折线图",
                symbolSize: 10, // 控制线条上 点 的大小
                itemStyle: {
                  normal: {
                    color: "#70CC58", //折点颜色
                    label: {
                      show: true,
                      position: "top",
                      color: "#333",
                      fontSize: 14,
                      fontWeight: 700,
                    },
                    borderWidth: 5,
                    lineStyle: {
                      color: "#70CC58", //折线颜色
                      width: 5,
                    },
                  },
                },
              },
            ],
          });
        }
        // 转出ba64格式图片
        let imgURL = ageData[i].getDataURL({
          pixelRatio: 1,
        });
        this.wordImgUrl.push(imgURL);
      }
    },
    // 导出word
    downWord() {
      let isTmp = true; // 关闭弹出会触发 catch。    控制 是否到导出完整测评报告   默认允许导出
      let num = 0;
      if (this.uid.ids) {
        num = this.uid.ids.split(",").length;
      } else {
        num = this.uid.id.split(",").length;
      }
      $(".pageNext").html(
        '<br clear=all style="page-break-before:always" mce_style="page-break-before:always">'
      );

      // ========================================================================
      if (num > 1) {
        this.$confirm("每份报告是否需要独立导出？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            isTmp = false; // 禁止关闭触发 导出
            // 单个文件-批量导出
            this.downLoad = true;
            let wordZip = new Promise((resolve, reject) => {
              var zip = new jszip();
              for (let i = 0; i < num; i++) {
                let title = this.tableInfoList[i].measure_title;
                let name = this.tableInfoList[i].customer.name;
                let date = this.tableInfoList[i].customer.evaluating_time;
                setTimeout(() => {
                  let bolo = $(`#downWord${i}`).wordExport("null");
                  zip.file(`${name}-${title}.doc`, bolo);
                  this.downLoadNum = i + 1;
                  this.downLoadTitle = title + "——" + name;
                  if (i == num - 1) {
                    this.zipData = wordZip;
                    resolve(zip);
                  }
                }, 200 * i);
              }
            });
            wordZip.then((res) => {
              res.generateAsync({ type: "blob" }).then(function (content) {
                var now = moment().format("YYYY-MM-DD HH:mm:ss");
                saveAs(content, `批量导出Word-${now}.zip`);
              });
            });
            // ====================================
          })
          .catch(() => {
            if (isTmp) {
              var now = moment().format("YYYY-MM-DD HH:mm:ss");
              $(`#downWord`).wordExport(`完整测评报告-${now}`);
            }
          });
      } else {
        let title = this.tableInfoList[0].measure_title;
        let name = this.tableInfoList[0].customer.name;
        $(`#downWord`).wordExport(`${name}-${title}`);
      }
    },
    // 手动下载
    twoDownWord() {
      this.zipData.then((res) => {
        res.generateAsync({ type: "blob" }).then(function (content) {
          var now = moment().format("YYYY-MM-DD HH:mm:ss");
          saveAs(content, `批量导出Word-${now}.zip`);
        });
      });
    },
    // 转Base64
    convertImgToBase64(url, callback, outputFormat) {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat || "image/png");
        callback.call(this, dataURL);
        canvas = null;
      };
      img.src = url;
    },
    // 编辑内容
    infoEditState() {
      this.editState = true;
    },
    // 保存修改的内容
    seveEditInfo() {
      this.editState = false;
      // console.log(this.tableInfoList[0]);
      // 数据处理
      let data = JSON.parse(JSON.stringify(this.tableInfoList[0]));
      data.base_results = data.factor_results[0];
      data.factor_results.shift();
      console.log(data);
      let a = {
        id: this.$route.query.id,
        data,
      };
      save_meaconclusion(a).then((res) => {
        console.log(res);
      });
    },
  },
  filters: {
    waiting(val) {
      return val != 1 ? "正常" : "危险";
    },
  },
  watch: {
    chartDataList: function () {
      this.$nextTick(() => {
        this.charData();
        this.fullscreenLoading = false;
      });
    },
  },
};
</script>
<style lang="less">
.tc-report {
  position: relative;
  // 页面功能
  .tool {
    position: absolute;
    top: -50px;
    right: 0;
    overflow: hidden;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  //   顶部工具栏
  .tc-report-header {
    padding: 0 10px;
    height: 50px;
    background: rgb(217, 242, 247);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 50px;
    border-radius: 5px;
    min-width: 860px;
  }
  .SetReport {
    position: absolute;
    top: 20;
    left: 0;
    z-index: 9999;
    background: rgb(201, 239, 247);
    padding: 10px;
    border-radius: 10px;
  }
  // 报告
  .tc-report-main {
    width: 100%;
    // 报告内容部分
    .content {
      width: 900px;
      margin: auto;
      background: white;
      padding: 20px 60px;
      box-sizing: border-box;
      // 报表头部
      .report-title {
        font-size: 30px;
        text-align: center;
      }
      //   用户信息
      .userInfo {
        margin-top: 20px;
        table {
          margin: auto;
          width: 100%;
          border-top: 1px black dashed;
          border-left: 1px black dashed;
          tr {
            width: 100%;
            // font-size: 16px;
            font-weight: 700;
            td {
              padding: 5px;
              border-bottom: 1px black dashed;
              border-right: 1px black dashed;
              &:nth-child(1) {
                width: 220px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              &:nth-child(3) {
                width: 300px;
              }
              span {
                // font-size: 14px;
                font-weight: 300;
              }
            }
          }
        }
      }
      //   测试结果标题.
      .test-msg {
        padding-left: 0px;
        margin-top: 10px;
        font-size: 25px;
        color: orange;
        text-shadow: black 2px 1px 1px;
        font-weight: 700;
      }
      //    图表
      .chart {
        margin-top: 10px;
        height: 350px;
        #chart-report {
          margin: 0 auto;
          //   border: black 1px solid;
        }
      }
      //   表格
      .table {
        overflow: hidden;
        margin-top: 15px;
        box-sizing: border-box;
        padding: 20px 0;
        border-top: 1px black solid;
        border-bottom: 1px black solid;
        .table-main {
          .one,
          .two {
            width: 100%;
            border-top: 1px black dashed;
            border-left: 1px black dashed;
            tr {
              width: 100%;
              th {
                border-right: 1px black dashed;
                border-bottom: 1px black dashed;
                padding: 5px 0;
              }
              td {
                text-align: center;
                border-right: 1px black dashed;
                border-bottom: 1px black dashed;
                padding: 5px 0;
                font-size: 14px;
                &:nth-child(1) {
                  // width: 180px;
                }
                &:nth-child(2) {
                  // width: 150px;
                }
                &:nth-child(3) {
                  min-width: 40px;
                  text-align: center;
                }
                .table-icon {
                  padding-left: 5px;
                }
              }
            }
          }
          .one {
          }
          .two {
            margin-left: -3px;
            border-left: 1px black dashed;
          }
          .results-two {
            width: 100%;
            border-top: 1px dashed black;
            border-left: 1px dashed black;
            tr {
              th {
                padding: 5px 0;
                border-bottom: 1px dashed black;
                border-right: 1px dashed black;
                &:nth-child(1) {
                  width: 160px;
                }
                &:nth-child(2) {
                  width: 180px;
                }
                &:nth-child(3) {
                  width: 50px;
                }

                &:nth-child(4) {
                  width: 160px;
                }
                &:nth-child(5) {
                  width: 180px;
                }
                &:nth-child(6) {
                  width: 50px;
                }
              }
              td {
                box-sizing: border-box;
                padding: 2px 5px 2px 5px;
                vertical-align: middle;
                border-bottom: 1px dashed black;
                border-right: 1px dashed black;
                text-align: center;
                &:nth-child(1) {
                  width: 160px;
                }
                &:nth-child(2) {
                  width: 180px;
                }
                &:nth-child(3) {
                  width: 50px;
                }

                &:nth-child(4) {
                  width: 160px;
                }
                &:nth-child(5) {
                  width: 180px;
                }
                &:nth-child(6) {
                  width: 50px;
                }
              }
            }
          }
        }
      }
      //   健康状态
      .condition {
        margin-top: 10px;
        border-bottom: 1px black solid;
        padding-bottom: 14px;
        table {
          tr {
            td {
              text-indent: 2em;
              letter-spacing: 3px;
              line-height: 25px;
              // font-size: 15px;
            }
          }
          .condition-title {
            text-indent: 0;
            padding-top: 10px;
            // font-size: 16px;
            font-weight: 700;
          }
        }
      }
      //   每项分析
      .analyze {
        margin-top: 20px;
        padding-bottom: 10px;

        table {
          padding: 10px 0;
          border-bottom: 1px black solid;
          width: 100%;
          tr {
            td {
              text-indent: 2em;
              letter-spacing: 3px;
              line-height: 25px;
              // font-size: 15px;
            }
          }
          .analyze-title {
            text-indent: 0;
            padding-top: 10px;
            // font-size: 16px;
            font-weight: 700;
            .el-input {
              width: 100px;
              &:nth-child(1) {
                width: 180px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              &:nth-child(3) {
                width: 100px;
              }
            }
          }
        }
      }
      .degree {
        margin: 10px 0;
        p {
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
      // 用户答案
      .user-answers {
        .user-answers-item {
          margin-bottom: 10px;
          color: black;
          &:nth-child(2) {
            padding-top: 10px;
          }
          .title {
          }
          .answers {
            font-weight: 700;
            display: inline-block;
          }
        }
      }
      // 签名
      .signature {
        position: relative;
        text-align: right;
        margin-top: 40px;
        .title {
          font-weight: 700;
          .signature_img {
            display: inline-block;
            min-width: 100px;
          }
        }
        .data {
          margin-left: 20px;
        }
        img {
          // position: absolute;
          // top: -40px;
          // left: 284px;
        }
      }
    }
    // 明尼苏达
    .minnesota {
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .remind {
        border-top: 1px black solid;
        border-bottom: 1px black solid;
        padding: 5px 0;
      }
      .el-divider--horizontal {
        margin: 5px 0;
      }
      .el-divider {
        background-color: rgb(133, 133, 133);
      }
      .mmpi {
        margin-top: 40px;
        .mmpi_table {
          margin-top: 20px;
          table:nth-child(1) {
            width: 100%;
            font-size: 14px;
            tr:nth-child(1) {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  // text-align: center;
                  height: 120px;
                  writing-mode: vertical-lr;
                  letter-spacing: 8px;
                }
              }
            }
            tr:nth-child(2) {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          table:nth-child(3) {
            width: 100%;
            font-size: 14px;
            tr {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          .mmpi_summarize {
            padding: 10px 0;
          }
        }
      }
      .t {
        margin-top: 40px;
        .t_table {
          margin-top: 20px;
          > table:nth-child(1) {
            width: 100%;
            font-size: 14px;
            tr {
              display: flex;
              > td {
                flex: 1;
                display: flex;
                justify-content: center;
                > span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          > table:nth-child(2) {
            width: 100%;
            font-size: 14px;
            border: black 1px solid;
            border-collapse: collapse;
            tr {
              &:nth-child(1) {
                td:nth-child(4) {
                  // display: flex;
                  span {
                    display: inline-block;
                    width: 9%;
                    justify-content: space-around;
                    position: relative;
                    text-align: center;
                    &::after {
                      font-size: 17px;
                      content: "|";
                      position: absolute;
                      bottom: -16px;
                      right: 9px;
                      z-index: 999;
                    }
                    &::before {
                      font-size: 10px;
                      content: "|||||||||";
                      position: absolute;
                      bottom: -11px;
                      right: -3px;
                      z-index: 999;
                    }
                  }
                }
              }
              td {
                text-align: center;
                border-right: black 1px solid;
                border-bottom: black 1px solid;
                &:nth-child(1) {
                  width: 100px;
                }
                &:nth-child(2) {
                  width: 50px;
                }
                &:nth-child(3) {
                  width: 180px;
                }
                &:nth-child(4) {
                  position: relative;
                  text-align: left;
                  font-size: 10px;
                }
                &:nth-child(5) {
                  width: 150px;
                }
              }
            }
            .cursor {
              color: black;
              display: inline-block;
              position: absolute;
              .el-icon-caret-top {
                font-size: 20px;
              }
            }
          }
          .forecast {
            margin-top: 30px;
            table {
              width: 100%;
              caption {
                font-size: 16px;
                font-weight: 700;
                padding: 10px 0;
              }
              tr {
                &:nth-child(2) {
                  font-weight: 700;
                  td {
                    &:nth-child(1) {
                      width: 120px;
                    }
                  }
                }
                td {
                  width: 50px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .conclusion {
        margin-top: 40px;
        table {
          width: 100%;
          border-collapse: collapse;
          caption {
            padding: 5px 0;
            font-weight: 700;
            font-size: 18px;
          }
          tr {
            th {
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
              &:nth-child(1) {
                width: 200px;
                text-align: center;
              }
              &:nth-child(2) {
                width: 150px;
                text-align: center;
              }
              &:nth-child(3) {
              }
            }
            td {
              font-size: 14px;
              padding: 5px 0;
              // vertical-align: top;
              &:nth-child(1) {
              }
              &:nth-child(2) {
                text-align: center;
              }
            }
          }
        }
      }
      .auxiliary {
        margin-top: 40px;
        .auxiliary_title {
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
        }
        .auxiliary_main {
          .auxiliary_item {
            padding: 10px 0;
            .item_title {
              font-weight: 700;
              margin-bottom: 8px;
            }
          }
        }
      }
      .describe {
        margin-top: 40px;
        .describe_main {
          .describe_content_title {
            font-size: 16px;
            text-align: center;
            p {
              margin: 30px 0px 9px 0px;
              font-size: 16px;
              font-weight: 700;
            }
          }
          .describe_content {
            font-size: 14px;
            padding: 10px 0;
          }
        }
      }
      .zDescribe {
        margin-top: 60px;
        .zDescribe_title {
          font-weight: 700;
          text-align: center;
        }
        .zDescribe_content {
          margin-top: 40px;
          font-size: 14px;
        }
        .zDescribe_msg {
          margin-top: 50px;
          p {
            font-weight: 700;
            span {
              font-weight: 100;
            }
          }
        }
      }
      .suggest {
        border-top: 1px black solid;
        margin-bottom: 5px;
        padding: 5px 0;
        .suggest_msg {
          font-weight: 700;
        }
        .suggest_content {
          height: 200px;
        }
      }
    }
    .report-size {
      font-size: 16px !important;
    }
  }
  .downWordMsg {
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    position: absolute;
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .tc-report {
    zoom: 0.55;
    overflow: auto;
  }
}
</style>